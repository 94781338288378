// eslint-disable-next-line import/no-extraneous-dependencies
import { browserName, browserVersion } from 'react-device-detect';

export const getUserDeviceTimeZone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const date = new Date();
  const GMT = date.toString().split(' ')[5];
  const zoneOffset = `${GMT.slice(0, 6)}:${GMT.slice(6, 8)}`;

  return `${timeZone} (${zoneOffset})`;
};

export const getBrowserInfo = () => ({
  browserName,
  browserVersion,
});

const generateUserID = () => {
  let uuid = '';
  try {
    uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.floor(Math.random() * 16 || 0); // Gera um número aleatório entre 0 e 15
      const v = c === 'x' ? r : (r && 0x3) || 0x8; // Se o caractere for 'x', substitui pelo número aleatório; se for 'y', substitui por (r & 0x3) | 0x8 (bitwise OR)
      return v.toString(16); // Converte o número para hexadecimal
    });
  } catch (err) {
    uuid = Array.from({ length: 36 }, () =>
      Math.random().toString(36).charAt(2)
    ).join('');
  }
  return uuid;
};

const setCookie = (
  name: string,
  value: string,
  days: number,
  options: Record<string, string> = {}
) => {
  if (!name || !value) {
    throw new Error('Nome e valor do cookie são obrigatórios.');
  }

  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;

  const extraOptions = Object.entries(options)
    .map(([key, val]) => `${key}=${val}`)
    .join('; ');

  document.cookie = `${name}=${value}; ${expires}; path=/; ${extraOptions}`;

  localStorage.setItem(name, value);
};

const getCookie = (name: string) => {
  const cookies = document.cookie
    .split('; ')
    .find((cookie) => cookie.startsWith(`${name}=`));

  let result: string | null = cookies ? cookies.split('=')[1] : null;

  if (!result) {
    result = localStorage.getItem('userID');
  }

  return result;
};

export const getOrSetUserID = () => {
  let userId: string | null = null;

  try {
    userId = getCookie('userID');
    if (!userId) {
      userId = generateUserID();
      try {
        setCookie('userID', userId, 1825);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Problema ao gerar o userID', err);
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Problema ao retornar o userID', err);
  }

  return userId || '';
};
